import React, { useState } from 'react';
import { Grid, makeStyles, Paper, Typography } from '@material-ui/core';
import WhatsAppIcon from '@material-ui/icons/WhatsApp';
import UpdateIcon from '@material-ui/icons/Update';
import FavoriteIcon from '@material-ui/icons/Favorite';
import FlareIcon from '@material-ui/icons/Flare';
import InstagramIcon from '@material-ui/icons/Instagram';
import RoomIcon from '@material-ui/icons/Room';
import Player from '../../components/player';
import ExternalLayout from '../../components/layout/external-layout';
import SimpleModalIframe from '../../components/simple-modal-iframe';

const useStyles = makeStyles(() => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    textAlign: 'center',
    padding: 10,
    height: 250,
  },
  container: {
    paddingTop: 80,
  },
  icon: {
    width: 60,
    height: 60,
    color: '#5c5c5f'
  },
  title: {
    color: '#5c5c5f',
    textDecoration: 'none',
  },
  text: {
    color: 'white',
    fontFamily: 'Advent Pro',
  },
  intro: {
    textAlign: 'center',
    fontSize: 20,
    fontFamily: 'Advent Pro',
  },
  big: {
    fontSize: 30,
  },
  iconText: {
    fontSize: 50,
    color: '#5c5c5f',
    textDecoration: 'none',
  },
}));

export default function AppIndexPage() {
  const classes = useStyles();
  const [openPlayer, setOpenPlayer] = useState(false);
  const [openIframe, setOpenIframe] = useState(false);

  const items = [
    { title: 'Ambientes', link: '/app/environments', icon: <FavoriteIcon className={classes.icon} /> },
    { title: 'Instagram', link: 'https://www.instagram.com/criareflorianopolis/', icon: <InstagramIcon className={classes.icon} /> },
    { title: 'Nova Coleção', iconText: 'Átomo', player: 'https://www.youtube.com/watch?v=PoXrpiR2Nqw' },
    { title: 'SHOWROOM 360º', modalIframe: 'https://www.criare.com/showroom/', icon: <UpdateIcon className={classes.icon} /> },
    { title: 'DESCUBRA O SEU ESTILO', link: '/quiz', icon: <FlareIcon className={classes.icon} /> },
    { title: 'SOLICITE O SEU PROJETO PERSONALIZADO', link: 'https://api.whatsapp.com/send?phone=5548991674238&text=Olá, sou cliente Winker e quero solicitar um projeto personalizado.', icon: <WhatsAppIcon className={classes.icon} /> },
    { title: 'Endereço', link: 'https://g.page/criareflorianopolis/', icon: <RoomIcon className={classes.icon} /> },
  ];

  const spot = (item) => {
    return (
      <>
        {item.icon && (item.icon)}
        <Typography className={classes.title} variant="overline" display="block" gutterBottom>{item.title}</Typography>
        {item.iconText && (<div className={classes.iconText}>{item.iconText}</div>)}
        {item.subtitle && (<Typography className={classes.subtitle} variant="overline" display="block" gutterBottom>{item.subtitle}</Typography>)}
      </>
    );
  };
  return (
    <ExternalLayout>
      <h1 style={{color: 'white'}}>
        Móveis e ambientes planejados
      </h1>
      <Grid container className={classes.root} spacing={2}>
        {items.map(item => {
          return (
            <Grid item lg={4} xl={4} xs={12} sm={6} md={6}>
              <Paper className={classes.paper} elevation={3}>
                <div className={classes.container}>
                  {item.link && (<a href={item.link} style={{ textDecoration: 'none' }}>{spot(item)}</a>)}
                  {item.player && (
                    <div class="hand-click" onClick={() => setOpenPlayer({ title: item.title, url: item.player })}>
                      {spot(item)}
                    </div>
                  )}
                  {item.modalIframe && (
                    <div class="hand-click" onClick={() => setOpenIframe({ title: item.title, url: item.modalIframe })}>
                      {spot(item)}
                    </div>
                  )}
                </div>
              </Paper>
            </Grid>
          );
        })}
      </Grid>

      <Player
        open={openPlayer}
        onClose={() => setOpenPlayer(false)}
        title={openPlayer?.title}
        url={openPlayer?.url}
      />
      <SimpleModalIframe
        open={openIframe}
        onClose={() => setOpenIframe(false)}
        title={openIframe?.title}
        url={openIframe?.url}
      />
    </ExternalLayout>
  );
}