import React from 'react';
import Container from '@material-ui/core/Container';
import { Button, makeStyles } from '@material-ui/core';
import { Helmet } from "react-helmet";
import WhatsAppIcon from '@material-ui/icons/WhatsApp';
import InstagramIcon from '@material-ui/icons/Instagram';
import RoomIcon from '@material-ui/icons/Room';

const useStyles = makeStyles(() => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    textAlign: 'center',
    padding: 10,
    height: 250,
  },
  container: {
    paddingTop: 80,
  },
  icon: {
    width: 60,
    height: 60,
    color: '#5c5c5f'
  },
  title: {
    color: '#5c5c5f',
    textDecoration: 'none',
  },
  text: {
    color: 'white',
    fontFamily: 'Advent Pro',
  },
  intro: {
    textAlign: 'center',
    fontSize: 20,
    fontFamily: 'Advent Pro',
  },
  big: {
    fontSize: 30,
  },
  iconText: {
    fontSize: 50,
    color: '#5c5c5f',
    textDecoration: 'none',
  },
}));

export default function ExternalLayout({
  children,
}) {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Helmet>
        <html class='dark' />
        <body class='with-margin' />
        <link href='https://fonts.googleapis.com/css?family=Advent Pro' rel='stylesheet' />
      </Helmet>

      <Container component="main" maxWidth="md">
        <table style={{ width: '100%' }}>
          <tr>
            <td><img src={`/images/logo_criare.png`} alt="Logo Criare" height={30} /></td>
            <td style={{ textAlign: 'right' }}>
              <Button href="https://www.instagram.com/criareflorianopolis/" target="_blank" style={{ color: '#D55DB0' }} variant="text" color="primary" size="large" startIcon={<InstagramIcon />}></Button>
              <Button href="https://g.page/criareflorianopolis/" target="_blank" style={{ color: '#B39CCF' }} variant="text" color="primary" size="large" startIcon={<RoomIcon />}></Button>
              <Button href="https://api.whatsapp.com/send?phone=5548991674238&text=Olá, sou cliente Winker e quero solicitar um projeto personalizado." target="_blank" style={{ color: '#4fff4f' }} variant="text" color="primary" size="large" startIcon={<WhatsAppIcon />}>(48) 99167-4238</Button>
            </td>
          </tr>
        </table>
        <br />
        {children}
      </Container>
    </div>
  );
}